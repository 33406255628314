


























































import { Vue, Component } from "vue-property-decorator";
import { BAvatar, BDropdown, BDropdownDivider, BDropdownItem, BBadge } from "bootstrap-vue";
import { CompanyPricingPlan, IAuthLoginResultDto } from "@lib";
import AltIcon from "@/core/components/alt-icon";
import { configuration } from "@/configuration";

@Component({
    components: {
        BAvatar,
        BDropdown,
        BDropdownDivider,
        BDropdownItem,
        BBadge,
        AltIcon,
    },
})
export default class ProfileDropdown extends Vue {
    private readonly DocsUrl = process.env.VUE_APP_DOCS_BASEURL;
    private balance: number | null = null;

    private balanceCritical = configuration.prices.standart.fee;
    private balanceWarning2 = configuration.prices.standart.fee * 2;
    private balanceWarning1 = configuration.prices.standart.fee * 3;

    private get showLogoutCompanyButtons(): boolean {
        return this.$alt.isFeatureEnabled("feature-switch-company");
    }

    public async created(): Promise<void> {
        if (process.env.VUE_APP_ADMIN_MODE) {
            return;
        }

        await this.init();
    }

    private async init(): Promise<void> {
        this.balance = await this.getBalance();

        this.$info.onBalanceChanged = (balance: number) => {
            this.balance = balance;
        };
    }

    private async getBalance(): Promise<number | null> {
        const pricing = await this.$info.getPricing();
        if (pricing?.plan === CompanyPricingPlan.Free10) {
            return null;
        }

        return await this.$info.getBalance();
    }

    private profile(): void {
        this.$router.push("/settings/profile").catch(() => {});
    }

    private company(): void {
        this.$router.push("/settings/company").catch(() => {});
    }

    private payment(): void {
        // TODO: сделать переход к оплате
        //this.$router.push({ path: 'settings', query: { payment: '' } }).catch(() => {})
        this.$router.push("/settings/company/payments").catch(() => {});
    }

    private async logoutCompany(): Promise<void> {
        const yes = await this.$alt.message.confirm("Вы уверены, что хотите сменить компанию?", "Смена компании", {
            acceptText: "Да",
            cancelText: "Нет",
            color: "primary",
        });

        if (yes) {
            await this.doLogoutCompany();
        }
    }

    private async doLogoutCompany(): Promise<void> {
        try {
            const result = await this.$alt.system.usecase.createAuthCompanyUseCase().logoutCompany();
            this.applyUserOnlyData(result);
            await this.$router.push({ name: "switch-company" }).catch(() => {});
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        }
    }

    private async applyUserOnlyData(result: IAuthLoginResultDto): Promise<void> {
        this.$alt.system.token.cleanData();
        this.$alt.system.token.setUserOnlyToken(result.accessToken);

        this.$info.setAuthInfo(result.data);
        this.$settings.setUserInfo(result.data.user.user.id);
    }

    private async logout(): Promise<void> {
        const yes = await this.$alt.message.confirm("Вы уверены, что хотите выйти?", "Выход", {
            acceptText: "Да",
            cancelText: "Нет",
            color: "primary",
        });

        if (yes) {
            await this.doLogout();
        }
    }

    private async doLogout(): Promise<void> {
        try {
            this.$secure.reset();
            this.$info.clean();
            this.$alt.system.token.cleanData();

            await this.$router.push({ name: "login" }).catch(() => {});
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        }
    }
}
